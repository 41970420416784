/* Used to detect in JavaScript if apps have loaded styles or not. */
:root {
  --reach-menu-button: 1;
}

[data-reach-menu] {
  display: block;
  position: absolute;
}

[data-reach-menu-list] {
  display: block;
  white-space: nowrap;
  outline: none;
}

.MenuDropdown_button__7VnBO {
  color: var(--accent-basePlus25);
  background: none;
  border: none;
  width: 100%;
  outline: none;
  cursor: pointer;
  font-size: 0.75rem;
  margin: 0;
  padding: 0;
}

.MenuDropdown_button__7VnBO > svg {
  display: block;
}

.MenuDropdown_menuList__25VG2 {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  min-width: 7rem;
}

:root {
  --reach-menu-button: 1;
}

[data-reach-menu-item] {
  /* reach-menu-item */
  cursor: pointer;

  /* a */
  display: block;
  color: inherit;
  font: inherit;
  text-decoration: none;
  text-decoration: initial;
}

/* pseudo pseudo selector */
[data-reach-menu-item][data-selected] {
  outline: none;
}

.MenuDropdownItem_item__B9HSI {
  width: 100%;
  cursor: pointer;
  background-color: white;
  font-size: 0.75rem;
  color: var(--accent-base);
  border: 0;
  text-align: left;
  height: 2.5rem;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.MenuDropdownItem_item__B9HSI:not(:last-child) {
  border-bottom: 1px solid var(--ambient-basePlus90);
}

.MenuDropdownItem_item__B9HSI:hover {
  background-color: var(--accent-basePlus90);
}

.MenuDropdownItem_children__2tarD {
  min-width: 7rem;
  padding: 0.375rem 1rem;
}

